import { Pipe, PipeTransform } from '@angular/core';
import { TimeUnit } from '@app/shared/duration.helper';
import { translationMapping } from '@app/shared/translations';
import { TranslateService } from '@ngx-translate/core';
import { decimalHoursToDurationParts } from '@shiftbase-com/utilities';

/**
 * TODO: we had to temporarily revert the way hour durations are split up because
 * mobile didn't have same rounding behavior yet. The examples below are therefore
 * not accurate for the time being.
 *
 * Take heed: the provided specs for this are very specific and not consistent at all
 * with what the (not yet final) Intl.DurationFormat api or datefns's formatDuration provide.
 * Don't expect this to be a catch all to format each and every possible duration.
 *
 * Example of expected behavior for durations in hours:
 * - `123.41` -> `123h 24m` (rounding down to the nearest minute)
 * - `123` -> `123h 0m` (minutes should still show when 0)
 * - `0.41` -> `0h 24m` (hours should still show when 0)
 * - `0` -> `0h 0m` (hours and minutes should still show wen 0)
 *
 * Examples of expected behavior for durations in days:
 * - `0` -> `0 days`
 * - `1` -> `1 day`
 * - `2` -> `2 days`
 * - `2.46` -> `2.4 days` (rounding down to one decimal place)
 *
 * Examples of expected behavior for durations in days with dayRoundingRule set to halfDays:
 * - `0.2` -> `0 days`
 * - `16.7` -> `16.5 day`
 * - `-1.3` -> `1.5 days` (rounding down to nearest half)
 */

export type DayRoundingRule = 'halfDays' | 'none' | number;

export interface DurationFormatOptions {
  dayRoundingRule?: DayRoundingRule;
  dayPrecision?: number;
}

@Pipe({
  name: 'decimalToDurationFormat',
  standalone: true,
})
export class DecimalToDurationFormatPipe implements PipeTransform {
  public constructor(private translate: TranslateService) {}

  public transform(value: number | string, unit: 'hours' | 'days', options?: DurationFormatOptions): string {
    if (Number.isNaN(value)) {
      return '';
    }

    switch (unit) {
      case TimeUnit.HOURS:
        return this.transformHourDuration(value);
      case TimeUnit.DAYS:
        return this.transformDayDuration(value, options);
      default:
        return value.toString(); // Not sure if this is a sensible fallback
    }
  }

  private transformHourDuration(value) {
    const parts = decimalHoursToDurationParts(value);
    const language = this.translate.currentLang ?? 'en-GB';
    const translations = translationMapping[language];
    return `${parts.sign === '-' ? parts.sign : ''}${parts.hours || 0}${translations.hour} ${parts.minutes || 0}${
      translations.minute
    }`;
  }

  private transformDayDuration(value: any, options?: DurationFormatOptions): string {
    const rounded = this.roundDayValue(value, options);
    const unitTranslation = this.dayOrDays(rounded);
    return `${rounded} ${unitTranslation}`;
  }

  private dayOrDays(value: number): string {
    return value === 1 || value === -1 ? this.translate.instant('day') : this.translate.instant('days');
  }

  private roundDayValue(value: number, options?: DurationFormatOptions) {
    switch (options?.dayRoundingRule) {
      case 'halfDays':
        return this.roundDownAndClampToHalfDays(value);

      case 'none':
        return value;

      default:
        return this.roundToDecimal(value, options?.dayPrecision || 1);
    }
  }

  private roundToDecimal(value: number, decimal: number): number {
    const factor = Math.pow(10, decimal);
    return Math.floor(value * factor) / factor;
  }

  private roundDownAndClampToHalfDays(value: number): number {
    const floor = Math.floor(value);
    const rest = +(value - floor).toFixed(5);

    return floor + 0.5 * Math.floor(2 * rest);
  }
}
