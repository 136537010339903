/**
 * @deprecated this thing is only here because `supportedUnits` in
 * the shared utilities package isn't exported yet.
 * Will remove once it is.
 */
export enum TimeUnit {
  YEARS = 'years',
  QUARTERS = 'quarters',
  MONTHS = 'months',
  WEEKS = 'weeks',
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
}
