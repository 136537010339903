import { LocaleLanguage } from '@app/+authenticated/shared/locale/locale.helper';

/**
 * Hardcoded translations because having keys like 'h' and 'm' in Phrase was deemed
 * confusing according to SHB-4483.
 * Eventually we want to extract these to a shared package (or get rid of them altogether
 * once the Intl.durationFormat api is final and we decide to adopt that)
 */
export const translationMapping: Record<LocaleLanguage, { hour: string; minute: string }> = {
  'nl-NL': {
    hour: 'u',
    minute: 'm',
  },
  'en-GB': {
    hour: 'h',
    minute: 'm',
  },
  'de-DE': {
    hour: 'S',
    minute: 'M',
  },
  'fr-FR': {
    hour: 'h',
    minute: 'm',
  },
  'es-ES': {
    hour: 'h',
    minute: 'm',
  },
  'pl-PL': {
    hour: 'g',
    minute: 'm',
  },
  'sv-SE': {
    hour: 't',
    minute: 'm',
  },
  'ro-RO': {
    hour: 'o',
    minute: 'm',
  },
};
